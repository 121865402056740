@import 'prismjs/themes/prism.css';
@import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
@import 'prismjs/plugins/line-highlight/prism-line-highlight.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "@next/nx-themes/themes/hey";
@import '~material-icons/iconfont/material-icons.css';


#gitver {
  position: absolute;
  top: 5px;
  right: 5px;
  text-align: right;
  width: 100%;
  font-size: 80%;
  color: #B3B6B7;
  z-index: 99;
}

.oss-login-form input {
  width: calc(100% - 64px);
}

h1 {
  margin: 10px 0px 25px 0px;
  font-weight: bold;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.label-inline {
  display: inline !important;
}

.input-form-full{
  width:85%
}

hr{
  margin-bottom: 20px;
}

.pointer{
  cursor: pointer;
}

input {
  margin-top: 0;
  width: 100%;
  box-sizing: border-box;
  height: inherit;
}
